h4 {
  margin: 20px 0 20px;
  font-size: 5vw;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  text-align: center;
  color: #636363;
}


.intro {
    text-align: center;
    padding: 3%;
    line-height: 2;
    font-size: 1.5vw;
    margin: auto;
}

.bottom-container {
    text-align: center;
    padding: 50px 0 20px;
}

.contact-me {
    text-align: center;
    padding: 10px 20px 10px 20px;
}

hr {
    border: dotted 6px;
    border-bottom: none;
    width: 5%;
    margin: 30px auto;
}

.btn-primary {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}


.btn {
    margin: .5em;
    box-shadow: 0 .3em .3em 0 rgba(0,0,0,0.2),0 .3em .3em 0 rgba(0,0,0,0.19) !important;
    border-radius: 2px;
    display: inline-block;
    font-weight: 400;
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    padding: .375rem .75rem;
    font-size: 1.5vw;
    text-align: center;
    line-height: 1.5;
    border-radius: .25rem;
    width: 10vw;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-secondary {
    color: #fff;
    background-color: #aaaaaa;
    border-color: #aaaaaa;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #bbbbbb;
    border-color: #bbbbbb;
}

.btn-primary:hover {
    color: #fff;
    background-color: #25bfd6;
    border-color: #25bfd6;
}

.top {
    text-align: center;
}


.backgroundImage {
    height: 100%;
    margin: 0;
    background-color: #e0ecff;
    background-size: cover;
    overflow: show;
    height: 100vh;
    min-height: 100vh;
}


.profileimg {
    width: 12vw;
}
