.App {
  text-align: center;
  fill: black !important;
  color: black !important;
  margin-left: 2%;
  margin-right: 2%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

rect {
  fill: black;
}

mask {
  fill: blue;
}

text {
  fill: orange;
  color: orange;
}

.Paper {
  overflow: hidden;
}

input {
  text-align: center;
}

label {
  width: 27% !important;
  float: initial;
}

h2 {
  margin: 2.5vw 1vw 2vw;
  font-size: 1.5vw;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  text-align: center;
}

svg {
  margin-top: 2%;
}

p {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 200;
}

.table_container {
  align-items: center;
  border: none;
  width: auto !important;
  float: center !important;
}

.include {
  width: auto;
}

h3 {
  margin: 2.5vw 1vw 2vw;
  font-size: 2.5vw;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 800;
  line-height: 1.235;
  letter-spacing: 0.00735em;
}

h5 {
  margin: 2.5vw 1vw 2vw;
  font-size: 2vw;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 800;
  line-height: 1.235;
  letter-spacing: 0.00735em;
}

.checkbox {
  text-align: center;
  margin-left: 28%;
}

h1 {
  margin: 20px 0 20px;
  font-size: 5vw;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  text-align: center;
  color: #636363;
}

.btn-primary {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-secondary {
  color: #fff;
  background-color: #aaaaaa;
  border-color: #aaaaaa;
}

h6 {
  margin: 2.5vw 1vw 2vw;
  font-size: 1.8vw;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  margin-top: 0%;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
  padding: .375rem .75rem;
  font-size: 3.5vw;
  line-height: 1.5;
  border-radius: .25rem;
  width: 9vw;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

